<template>
  <v-container
    class="products-list-page"
    fluid>
    <v-row>
      <v-col cols="12">
        <span class="header">Tracking Behavior (Warehouse) Report</span>
      </v-col>
    </v-row>
    <v-row class="products-row">
      <v-col cols="2">
        <v-select
          v-model="warehouseSelected"
          :items="warehouses"
          :menu-props="{ offsetY: true }"
          item-value="id"
          item-text="name"
          label="Warehouses"
          class="select-warehouse"
          return-object
          auto-select-first
          outlined
          hide-details
          dense />
      </v-col>
      <v-col
        md="6"
        offset-md="4"
        offset-sm="2"
        class="d-flex align-center date-picker-col">
        <date-range-picker
          v-model="saleDates"
          @change="dateChange($event)" />
      </v-col>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="behaviors"
          :items-per-page="999"
          hide-default-footer />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TrackiingBehaviorProvider from '@/resources/TrackingBehavior'
import { mapGetters, mapActions } from 'vuex'
import DateRangePicker from '@/components/DateRangePicker.vue'

const TrackingBehaviorService = new TrackiingBehaviorProvider()

export default {
  components: {
    DateRangePicker
  },
  data () {
    return {
      saleDates: [this.$dayjs().subtract(7, 'day').format('YYYY-MM-DD'), this.$dayjs().format('YYYY-MM-DD')],
      headers: [
        { text: 'พนักงาน', value: 'staff.email' },
        { text: 'ใบหยิบของ', value: 'orderToPick' },
        { text: 'Pick Pack', value: 'pickPack' },
        { text: 'Done', value: 'done' },
        { text: 'ทำซ้ำ', value: 'repeated' },
        { text: 'ยิงผิด', value: 'wrongProduct' }
      ],
      behaviors: [],
      warehouseSelected: null
    }
  },
  computed: {
    ...mapGetters({
      mapWarehouse: 'Store/mapWarehouse'
    }),
    warehouses () {
      return this.mapWarehouse.filter((warehouse) => warehouse.id !== 0)
    }
  },
  watch: {
    saleDates: {
      handler () {
        this.getBehaviors()
      },
      deep: true
    },
    warehouseSelected: {
      handler () {
        this.getBehaviors()
      },
      deep: true
    }
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setModal: 'Components/setModal',
      setLoading: 'Components/setLoading'
    }),
    async getBehaviors () {
      try {
        this.setLoading({
          active: true,
          clickAble: false,
          message: 'GETTING REPORT...'
        })

        const { data } = await TrackingBehaviorService.getTrackingBehaviorReportByWarehouse({
          start: this.saleDates[0],
          end: this.saleDates[1],
          warehouseId: this.warehouseSelected.id
        })
        this.behaviors = data
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `[GET-REPORT-ERROR]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
      }
    }
  }
}
</script>

<style scoped>
.header {
  font-size: 1.5rem;
  font-weight: bold;
}
</style>
